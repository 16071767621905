<template>
    <section>
      <b-row>
        <!--
        <bruna-icon icon="fa-solid fa-user-secret"></bruna-icon>
        <bruna-icon icon="TrendingUpIcon"></bruna-icon>
        --> 
  
        <b-col>
          <welcome-card></welcome-card>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col>
          <home-card :dataUrl="homeResumen0"></home-card> 
        </b-col>
        <b-col>
          <home-card :dataUrl="homeResumen2"></home-card>
        </b-col>
        <b-col>
          <home-card :dataUrl="homeResumen3"></home-card>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col>
          <home-card :dataUrl="homeResumen1"></home-card>
        </b-col>
        <b-col>
          <home-card :dataUrl="homeResumen4"></home-card>
        </b-col>
        <b-col> </b-col>
      </b-row>
    </section>
  </template>
  <script>
  import
  {
    BFormDatepicker, BButton, BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardText,
    BCard,
    BLink,
    VBPopover,
    BSpinner,
    BOverlay,
    BMedia,
    BImg,
  
  } from "bootstrap-vue";
  

  import HomeCard from "../../base/components/HomeCard.vue";
  import WelcomeCard from "../../base/components/WelcomeCard.vue";
  
  import BrunaIcon from "@/@core/components/bruna-icon/BrunaIcon.vue";
  
  import useApiServices from '@/services/useApiServices.js';
  
  export default {
    components: {
      BButton,
      BRow,
      BCol,
      BFormDatepicker,
      BCard,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
      BLink,
      BCardText,
      BMedia,
      BImg,
      HomeCard,
      WelcomeCard,
      BrunaIcon
  
    },
    data()
    {
  
      
  
      return {
  
        
  
        homeResumen0: useApiServices.home.resumen0,
        homeResumen1: useApiServices.home.resumen1,
        homeResumen2: useApiServices.home.resumen2,
        homeResumen3: useApiServices.home.resumen3,
        homeResumen4: useApiServices.home.resumen4,
  
      }
  
  
    },
    created()
    {
  
  
  
    },
    methods: {
  
  
    },
    mounted()
    {
    }
  
  };
  </script>
  
  
  
<template>

    <feather-icon v-if="library == 'feather'" size="24" :icon="iconName" />
    <font-awesome-icon v-else-if="library == 'fa'" font-size="24" :icon="iconName" />
    <span v-else>ICONO_NO_DEFINIDO</span>
</template>
<script>




export default {
  props: {
    icon: {
      required: true,
      type: [String, Object],
    },
    size: {
      type: String,
      default: '14',
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: 'badge-primary',
    },
  },
  data() 
  {
    let libraryName = "feather"
    let iconName = this.icon

    if(iconName.includes("fa"))
    {
        libraryName = "fa"
    }
    else
    {
        libraryName = "feather"
    }

    return {
        library: libraryName,
        iconName: iconName
    };
  },
  components: 
  {
  },

  directives: 
  {
  },

  mounted()
  {
  },

  created()
  {
  },

  methods: 
  {
  },
};

</script>
<template>
    <b-card no-body>
      <div class="card-header">
        <!-- Title & SubTitle -->
        <div>
          <b-card-title></b-card-title>
          <b-card-sub-title></b-card-sub-title>
        </div>
      </div>
  
      <!-- Card Body  -->
      <b-card-body>
        <b-media vertical-align="top">
          <template #aside>
            <b-img
              :src="client.avatar"
              blank-color="#ccc"
              rounded="circle"
              width="64"
              alt="placeholder"
            />
          </template>
          <h4 class="media-heading">{{ client.name }}</h4>
          <b-card-text class="mb-0"
            >{{ $t('Te damos la bienvenida a la consola de administración de Bruna.') }}
          </b-card-text>
        </b-media>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  
  import axios from '@axios'
  
  import
  {
      BCard,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
      BMedia,
      BImg,
      BCardText
  
  } from "bootstrap-vue";
  
  export default {
  
    directives: {
    },
  
    props: {
  
    },
  
    data()
    {
  
      let userData = JSON.parse(localStorage.getItem("userData"));
  
      return {
          client: userData.client,
      };
    },
  
  
    components: {
      BCard,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
      BMedia,
      BImg,
      BCardText
    },
  
    created()
    {
      
  
  
    },
  
  
    methods: {
  
  
  
  
  
    },
  };
  </script>
  
  